import React, { useState, useEffect, useRef } from "react"
import fetchJsonp from "fetch-jsonp"

require("es6-promise").polyfill()

const SmartFeedJobAlerts = props => {
  const [jaDetails, setJaDetails] = useState({
    CompanyGroupName: props.CompanyGroupName,
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    keywords: "",
    geoLocation: "",
    Location: [],
    Region: [],
    Category: [],
    Industry: [],
    JobType: [],
    JobTime: [],
    MailFrequency: 86400000,
    acceptGDPR: false,
  })

  const [firstLoad, setFirstLoad] = useState(true)

  const [filterData, setFilterData] = useState({})
  const [locationData, setLocationData] = useState([])
  const [regionData, setRegionData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [industryData, setIndustryData] = useState([])
  const [jobTypeData, setJobTypeData] = useState([])
  const [jobTimeData, setJobTimeData] = useState([])

  const [showGDPR, setShowGDPR] = useState(false)
  const [GDPRStatement, setGDPRStatement] = useState("")
  const [apiResponse, setApiResponse] = useState("NA")

  const [validForm, setValidForm] = useState(false)
  const [errors, setErrors] = useState({})
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)

  useEffect(() => {
    if (props.group) {
      if (props.location) {
        getApiData("location", "GetLocationListByGroup", setLocationData)
      }
      if (props.region) {
        getApiData("region", "GetRegionListByGroup", setRegionData)
      }
      if (props.category) {
        getApiData("category", "GetCategoryListByGroup", setCategoryData)
      }
      if (props.industry) {
        getApiData("industry", "GetIndustryListByGroup", setIndustryData)
      }
    } else {
      if (props.location) {
        getApiData("location", "GetAllLocationsListByCompany", setLocationData)
      }
      if (props.region) {
        getApiData("region", "GetAllRegionsListByCompany", setRegionData)
      }
      if (props.category) {
        getApiData("category", "GetAllCategoriesListByCompany", setCategoryData)
      }
      if (props.industry) {
        getApiData("industry", "GetAllIndustriesListByCompany", setIndustryData)
      }
    }

    getGDPRApiData()

    setFirstLoad(false)
  }, [])

  const changeGDPRVal = () => {
    let temp = { ...jaDetails }

    temp.acceptGDPR = !temp.acceptGDPR

    setJaDetails(temp)
  }

  const getApiData = (name, apiCall, setState) => {
    fetchJsonp(
      "https://sf.vacancy-filler.co.uk/CareerPage/" +
        apiCall +
        "?id=" +
        props.apiKey
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(jsonList) {
        let uniqueArray = []

        uniqueArray.push("All")

        jsonList.map((item, i) => {
          if (!uniqueArray.includes(item)) {
            uniqueArray.push(item)
          }
        })

        setState(uniqueArray)
      })
  }

  const getGDPRApiData = (name, apiCall, setState) => {
    let gdprID = props.apiKey

    if (props.group) {
      gdprID = props.gdprKey
    }

    fetchJsonp(
      "https://sf.vacancy-filler.co.uk/CareerPage/GetGdprStatementByCompanyId?encodedCompanyId=" +
        gdprID
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(gdprStatement) {
        setGDPRStatement(gdprStatement)
      })
  }

  const subscribeToJobAlerts = () => {
    const serialize = function(obj) {
      var str = []
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
        }
      return str.join("&")
    }

    let toPass = {
      CompanyId: props.apiKey,
      TimeZoneDifferenceMinutes: 0,
      CompanyGroupName: props.CompanyGroupName,
      MailFrequency: jaDetails.MailFrequency,
      EmailAddress: jaDetails.EmailAddress,
      FirstName: jaDetails.FirstName,
      LastName: jaDetails.LastName,
      ReturnToSearchURL: props.ReturnToSearchURL,
      VacancyPageBaseURL: props.VacancyPageBaseURL,
      UnsubscribeLink:
        "https://new-smart-feed.vacancy-filler.co.uk/unsubscribe.html?UnsubscribeID=",
      OptInLink:
        "https://new-smart-feed.vacancy-filler.co.uk/optin.html?OptInID=",
      PostingType: "careers",
      Location:
        jaDetails.Location[0] && jaDetails.Location[0] === "All"
          ? ""
          : jaDetails.Location.join("|"),
      Region:
        jaDetails.Region[0] && jaDetails.Region[0] === "All"
          ? ""
          : jaDetails.Region.join("|"),
      Industry:
        jaDetails.Industry[0] && jaDetails.Industry[0] === "All"
          ? ""
          : jaDetails.Industry.join("|"),
      Category:
        jaDetails.Category[0] && jaDetails.Category[0] === "All"
          ? ""
          : jaDetails.Category.join("|"),
      Distance: 15,
      Unit: "Miles",
    }

    fetchJsonp(
      "https://sf.vacancy-filler.co.uk/CareerPage/SubscribeForJobAlert?id=" +
        props.apiKey +
        "&" +
        serialize(toPass)
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(result) {
        setApiResponse(result)
      })
  }

  const validateField = e => {
    console.log(e)
    switch (e.target.name) {
      case "firstName":
        if (!e.target.value) {
          setErrors({
            ...errors,
            firstName: "Please provide your First Name",
          })
          return false
        } else {
          setErrors({
            ...errors,
            firstName: null,
          })
          updateField("FirstName", e.target.value)
        }
        break
      case "lastName":
        if (!e.target.value) {
          setErrors({ ...errors, lastName: "Please provide your Last Name" })
          return false
        } else {
          setErrors({
            ...errors,
            lastName: null,
          })
          updateField("LastName", e.target.value)
        }
        break
      case "email":
        if (!e.target.value) {
          setErrors({
            ...errors,
            email: "Please provide your Email Address",
          })
          return false
        } else if (
          !String(e.target.value)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          setErrors({
            ...errors,
            email: "Invalid Email Address",
          })
          return false
        } else {
          setErrors({
            ...errors,
            email: null,
          })
          updateField("EmailAddress", e.target.value)
        }
        break
      case "gdpr":
        if (!e.target.checked) {
          setErrors({
            ...errors,
            gdpr: "You must accept the subscription statement to proceed",
          })
          return false
        } else {
          setErrors({
            ...errors,
            gdpr: null,
          })
        }
        break
      default:
        return false
    }
    return true
  }

  const validateForm = () => {
    var temp = { ...errors }
    if (!jaDetails.FirstName) {
      temp.firstName = "Please provide your First Name"
    }
    if (!jaDetails.LastName) {
      temp.lastName = "Please provide your Last Name"
    }
    if (!jaDetails.EmailAddress) {
      temp.email = "Please provide your Email Address"
    } else if (
      !String(jaDetails.EmailAddress)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      temp.email = "Invalid Email Address"
    }
    if (!jaDetails.acceptGDPR) {
      temp.gdpr = "You must accept the subscription statement to proceed"
    }

    setErrors(temp)

    if (temp.firstName) {
      firstNameRef.current.scrollIntoView()
      return false
    }
    if (temp.lastName) {
      lastNameRef.current.scrollIntoView()
      return false
    }
    if (temp.email) {
      emailRef.current.scrollIntoView()
      return false
    }

    return true
  }

  useEffect(() => {
    if (firstLoad) return

    if (errors.firstName || errors.lastName || errors.email || errors.gdpr)
      return setValidForm(false)

    setValidForm(true)
  }, [errors])

  const updateField = (name, val) => {
    let temp = { ...jaDetails }

    temp[name] = val

    setJaDetails(temp)
  }

  const addFilter = (label, item) => {
    let temp = { ...jaDetails }

    if (temp[label].includes(item)) {
      temp[label].splice(temp[label].indexOf(item), 1)
    } else {
      if (item === "All") {
        temp[label] = ["All"]
      } else {
        temp[label].push(item)
      }
    }

    setJaDetails(temp)
  }

  const isItemChecked = (label, item) => {
    if (jaDetails[label].includes(item)) {
      return true
    } else {
      return false
    }
  }

  const renderApiData = (label, state) => {
    return (
      <div className="col-12 col-xs-12 col-md-4 ja-filters-check-wrap">
        <div className="ja-filters-check-inner-wrap">
          <div className="ja-filters-section-title">
            <label>{label}:</label>
          </div>
          {state &&
            Array.isArray(state) &&
            state.map((item, i) => (
              <div className="vf-alerts-check-wrap">
                <input
                  checked={isItemChecked(label, item)}
                  onChange={() => addFilter(label, item)}
                  type="checkbox"
                  className="vf-alerts-check"
                  name={label}
                  value={item}
                  title={`Select ${label}`}
                  disabled={jaDetails[label].includes("All") && item !== "All"}
                />
                <label className="vf-alerts-check-label" htmlFor={label}>
                  {" "}
                  {item}
                </label>
              </div>
            ))}
        </div>
      </div>
    )
  }

  return (
    <form
      className="VF_Alerts_Wrap umbraco-forms-form"
      onSubmit={e => {
        e.preventDefault()
        if (validateForm()) subscribeToJobAlerts()
      }}
    >
      <div className="VF_Alerts_Inner_Wrap umbraco-forms-page">
        <fieldset className="umbraco-forms-fieldset">
          <legend>Your details</legend>

          <div className="row-fluid">
            <div className="umbraco-forms-container col-md-12">
              <div className=" umbraco-forms-field firstname firstname mandatory">
                <label
                  ref={firstNameRef}
                  htmlFor="cd03910b-c7b0-452e-fec7-0cdae9c5a543"
                  className="umbraco-forms-label"
                >
                  First name{" "}
                </label>
                <input
                  id="first-name"
                  className="VF-form-control onchange-search form-control"
                  onChange={validateField}
                  placeholder={"First Name"}
                  title={"Enter your first name"}
                  type="text"
                  name="firstName"
                ></input>
                {errors.firstName && (
                  <span className="text-danger">{errors.firstName}</span>
                )}
              </div>
              <div className=" umbraco-forms-field lastname lastname mandatory alternating">
                <label
                  ref={lastNameRef}
                  htmlFor="1931fd20-c97f-41c9-b74c-2e08a5b1e6c3"
                  className="umbraco-forms-label"
                >
                  Last name{" "}
                </label>
                <input
                  id="last-name"
                  className="VF-form-control onchange-search form-control"
                  onChange={validateField}
                  placeholder={"Last Name"}
                  title={"Enter your last name"}
                  type="text"
                  name="lastName"
                ></input>
                {errors.lastName && (
                  <span className="text-danger">{errors.lastName}</span>
                )}
              </div>
              <div className=" umbraco-forms-field email perplextextfield mandatory">
                <label
                  ref={emailRef}
                  htmlFor="d3a75ee9-6149-438a-e033-aabe9769ba95"
                  className="umbraco-forms-label"
                >
                  Email{" "}
                </label>
                <input
                  id="email"
                  className="VF-form-control onchange-search form-control"
                  onChange={validateField}
                  placeholder={"Email Address"}
                  title={"Enter your email address"}
                  name="email"
                ></input>
                {errors.email && (
                  <span className="text-danger">{errors.email}</span>
                )}
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="umbraco-forms-fieldset">
          <legend>Job alert subscription statement</legend>

          <div className="row-fluid">
            <div className="umbraco-forms-container col-md-12">
              <div className="gdprStatement">
                <div
                  dangerouslySetInnerHTML={{
                    __html: GDPRStatement.Statement,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: GDPRStatement.GDPRCustomMessage,
                  }}
                />
              </div>

              <input
                type="checkbox"
                id="gdpr"
                name="gdpr"
                value="acceptGDPR"
                className="acceptGDPR_Check"
                checked={jaDetails.acceptGDPR}
                onChange={e => {
                  changeGDPRVal()
                  validateField(e)
                }}
                title="Agree to the job alert subscription statement"
              />
              <label htmlFor="gdpr">
                I have read and understood this statement and I am happy to
                proceed.
              </label>
            </div>
            {errors.gdpr && <span className="text-danger">{errors.gdpr}</span>}
          </div>
        </fieldset>

        <div className="job-alert-actions">
          {validForm ? (
            <div className="subscrube-btn-wrap">
              {apiResponse === "OK" && (
                <span>You have successfully signed up to Job Alerts</span>
              )}
              {apiResponse === "Error" && (
                <span>There was an error, please try again</span>
              )}
              {apiResponse === "Updated" && (
                <span>
                  You have successfully updated your Job Alerts subscription
                </span>
              )}
              {apiResponse === "Pending" && (
                <span>
                  You have successfully signed up to Job Alerts, please check
                  your email inbox to confirm your subscription.
                </span>
              )}
              {apiResponse !== "OK" &&
                apiResponse !== "Updated" &&
                apiResponse !== "Pending" && (
                  <button
                    className="nav-action-sm btn btn-primary"
                    type="submit"
                  >
                    SUBSCRIBE TO JOB ALERTS
                  </button>
                )}
            </div>
          ) : (
            <div className="subscrube-btn-wrap">
              <button className="nav-action-sm disabled" type="submit">
                Please enter your details to subscribe
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

export default SmartFeedJobAlerts
